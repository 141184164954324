import Box from '@material-ui/core/Box'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

export default function MyDropzone(props) {
  const { allowedFiles, saveFileDataToState, saveFileToState, src } = props

  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        saveFileToState(binaryStr)
      }

      acceptedFiles.forEach((file) => {
        const img = reader.readAsDataURL(file)
        saveFileDataToState(file)
      })
    },
    [saveFileDataToState, saveFileToState]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Box
      {...getRootProps()}
      align="center"
      border={src ? '4px dashed #ffd100' : '6px dashed #ffd100'}
      borderRadius={5}
      px={src ? 3 : 6}
      py={src ? 0 : 12}
      style={{ cursor: 'pointer' }}
      width={src ? 'auto' : '100%'}
    >
      <input {...getInputProps()} accept={allowedFiles} />
      {src ? (
        <p>New file</p>
      ) : isDragActive ? (
        <p>Drop your file here...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </Box>
  )
}
