// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
// import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { saveAs } from 'file-saver'
import React, { useRef, useState } from 'react'
import Cropper from 'react-cropper'

import Dropzone from '../Dropzone'

const useStyles = makeStyles({
  root: {
    color: 'black !important'
  }
})

function handleDownload(cropResult, file) {
  const { name } = file
  if (!name) return
  saveAs(cropResult, name.replace(/\.\w*$/, '_edit'))
}

const ImageEditor = () => {
  const classes = useStyles()
  const [src, setSrc] = useState('')
  const [aspect, setAspect] = useState(16 / 9)
  const [file, setFile] = useState(null)
  const cropper = useRef(null)

  const saveFileDataToState = (fileData) => {
    setFile(fileData)
  }

  const saveFileToState = (binary) => setSrc(binary)

  // Used before Dropzone was implemented
  // onSelectImage = e => {
  //   if (e.target.files) {
  //     if (e.target.files.length > 1) {
  //       return alert("Please select only one file");
  //     }
  //
  //     if (e.target.files.length > 0) {
  //       const reader = new FileReader();
  //       reader.addEventListener("load", () =>
  //         this.setState({ src: reader.result })
  //       );
  //       reader.readAsDataURL(e.target.files[0]);
  //       this.setState({ file: e.target.files[0] });
  //     }
  //   }
  // };

  const _crop = async () => {
    if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
      return
    }

    const cropResult = await cropper.current.getCroppedCanvas().toDataURL()

    handleDownload(cropResult, file)
  }

  return (
    <Box>
      <Box display="flex" my={1}>
        <Dropzone
          allowedFiles="image/*"
          saveFileDataToState={saveFileDataToState}
          saveFileToState={saveFileToState}
          src={src}
        />
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box flex="1">
          {src && (
            <Cropper
              ref={cropper}
              aspectRatio={aspect}
              guides={false}
              preview="#img-preview"
              src={src}
              style={{ height: 400, width: '100%' }}
              viewMode={2}
            />
          )}
        </Box>
      </Box>

      {/* ASPECT RATIO RADIO BUTTONS */}
      <Box my={1}>
        <FormControl component="fieldset">
          <FormLabel className={classes.root} component="legend">
            Aspect Ratio
          </FormLabel>
          <RadioGroup
            aria-label="aspect-ratio"
            className={classes.root}
            name="aspect-ratio"
            value={aspect}
            onChange={(e) => setAspect(e.target.value)}
          >
            <Box display="flex" flexWrap="wrap">
              <FormControlLabel
                control={<Radio />}
                label="16:9"
                value={(16 / 9).toFixed(2)}
              />
              <FormControlLabel
                control={<Radio />}
                label="4:3"
                value={(4 / 3).toFixed(2)}
              />
              <FormControlLabel
                control={<Radio />}
                label="1:1"
                value={(1).toFixed(2)}
              />
              <FormControlLabel
                control={<Radio />}
                label="2:3"
                value={(2 / 3).toFixed(2)}
              />
              <FormControlLabel control={<Radio />} label="Free" value="NaN" />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>

      {/* ACTION BUTTONS */}
      <Box display="flex" my={1}>
        {/* <Box mr={1.5}>
            <Button
              variant="contained"
              color="primary"
              onClick={_crop}
              disabled={!src}
              // disabled={() => console.log("event")}
            >
              CROP
            </Button>
          </Box> */}

        <Button
          color="primary"
          disabled={!src}
          variant="contained"
          onClick={_crop}
        >
          DOWNLOAD
        </Button>
      </Box>
    </Box>
  )
  // }
}

// export default withStyles(styles)(ImageEditor)
export default ImageEditor
