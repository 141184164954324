import Box from '@material-ui/core/Box'
import NoSsr from '@material-ui/core/NoSsr'
import Typography from '@material-ui/core/Typography'
// import { useFirebase } from '@sb-konzept/firebase-hooks'
import { StoryblokComponent } from '@sb-konzept/gatsby-source-storyblok'
import FullScreenLoader from 'components/FullScreenLoader'
// import firebase from 'firebase'
// import * as firebaseui from 'firebaseui'
import { graphql, useStaticQuery } from 'gatsby'
import * as R from 'ramda'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Helmet } from 'react-helmet'

// import { firebaseConfig } from '../components/FirebaseWrapper' // { getFirebase }
import ImageEditor from '../components/ImageEditor'
import PageLayout from '../components/PageLayout'

// admin@twintaekwondo.de // asdf1234

const PhotoResizerPage = () => {
  const data = useStaticQuery(graphql`
    {
      layout: storyblokLayoutStory(full_slug: { eq: "layouts/default" }) {
        ...storyblokLayoutStory
      }
    }
  `)
  // const firebaseI = useFirebase()
  // const [firebase, setFirebase] = useState(false)
  // const [uiConfig, setUiConfig] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // // Lazy import Firebase
  // useEffect(() => {
  //   const lazyApp = import('firebase/app')
  //   const lazyAuth = import('firebase/auth')

  //   Promise.all([lazyApp, lazyAuth]).then(([firebaseI]) => {
  //     const FB = getFirebase(firebaseI)
  //     console.log('FB', FB)
  //     setFirebase(FB)
  //   })
  // }, [])

  // // Set config
  // useEffect(() => {
  //   const config = {
  //     credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  //     tosUrl: '/agb',
  //     signInFlow: 'popup',
  //     signInOptions: [
  //       {
  //         provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //         requireDisplayName: false
  //       }
  //     ],
  //     callbacks: {
  //       signInSuccessWithAuthResult: () => false // prevents redirect
  //     }
  //   }
  //   setUiConfig(config)
  // }, [firebase.auth.EmailAuthProvider.PROVIDER_ID])

  // // Initialise login widget
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(async (user) => {
  //       // console.log('Auth state changed:', !!user)
  //       if (user) {
  //         const { creationTime, lastSignInTime } = user.metadata
  //         if (creationTime === lastSignInTime) {
  //           await firebase.auth().signOut()
  //           alert('Sorry, new accounts are not allowed')
  //           // window.location.reload() // find a better way to reset/reload the login widget => in useEffect, shouldn't cause SSR error
  //           return
  //         }
  //         setIsAuthenticated(!!user)
  //       }
  //     })

  //   return () => {
  //     // console.log('Unsubscribing auth listener')
  //     unregisterAuthObserver()
  //   }
  // }, [firebase])

  // LAZY IMPORT OF FIREBASE
  // useEffect(() => {
  //   console.log('FIREBASE USEEFFECT')
  //   import('firebase').then((firebase) => {
  //     firebase.initializeApp(firebaseConfig)
  //     // firebase
  //     //   .firestore()
  //     //   .collection('items')
  //     //   .doc('yJd1Fs5Ampttq6QKBoYF')
  //     //   .get()
  //     //   .then((doc) => {
  //     //     // do stuff with Firestore data
  //     //   })
  //   })
  // }, [])

  const { layout } = data
  const { header, footer } = useMemo(
    () =>
      R.applySpec({
        header: R.pathOr(null, ['content', 'header', 0]),
        footer: R.pathOr(null, ['content', 'footer', 0])
      })(layout),
    [layout]
  )

  return (
    <PageLayout>
      <Helmet
        defaultTitle="TWIN-Taekwondo"
        defer={false}
        htmlAttributes={{ lang: 'de' }}
        title="TWIN-Taekwondo Photo Resizer"
      >
        <meta content="noindex,nofollow" name="robots" />
        <meta content="TWIN-Taekwondo" name="description" />
      </Helmet>
      {header && <StoryblokComponent content={header} />}

      <NoSsr>
        <Suspense fallback={<FullScreenLoader />}>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Box flex="1" height="100%" mx={{ xs: 2, md: '10vw' }} py={6}>
              <Box my={3}>
                <Typography component="h1" variant="h2">
                  {isAuthenticated ? 'FOTO RESIZER' : 'ADMIN'}
                </Typography>
                {isAuthenticated && (
                  <Typography component="p" variant="h5">
                    Upload, edit and then download your images
                  </Typography>
                )}
              </Box>

              {isAuthenticated ? (
                <Box>
                  <ImageEditor />
                </Box>
              ) : (
                <>
                  {/* MUST figure out the SSR firebase init */}
                  {/* <StyledFirebaseAuth
                  firebaseAuth={firebase.auth()}
                  uiCallback={(ui) => ui.disableAutoSignIn()}
                  uiConfig={uiConfig}
                /> */}
                </>
              )}
            </Box>
          </Box>
        </Suspense>
      </NoSsr>

      {footer && <StoryblokComponent content={footer} />}
    </PageLayout>
  )
}

export default PhotoResizerPage
